define('couriers-portal/pods/consignments/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),
    applicationController: _ember['default'].inject.controller('application'),
    config: _ember['default'].inject.service('config'),

    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 50,
    totalPages: null,

    searchValue: "",
    searchState: "",
    searchStartDate: "",
    searchEndDate: "",

    filterState: [],
    currentStateValues: [{ id: 0, value: "Courier Checkin" }, { id: 1, value: "Newsagency Checkin" }, { id: 2, value: "Delivered" }, { id: 3, value: "Courier Return Requested" }, { id: 4, value: "Courier Return Picked Up" }, { id: 5, value: "Outbound Consignment Lodged" }, { id: 6, value: "Awaiting Courier Pickup Outbound" }, { id: 7, value: "Processed Externally" }],

    stateValues: ["NSW", "QLD", "TAS", "VIC", "NT", "WA", "SA", "ACT"],
    perPageValues: [10, 25, 50, 100],

    csvURL: (function () {

      /** LIMIT LIST DATA TO ROLLING 6 MONTHS **/
      var maxallowedenddate = 0;

      var currenttime = Math.floor(Date.now() / 1000);

      // Get a date object for the current time
      var maxallowedtime = new Date();

      // Set it to one month ago
      maxallowedtime.setMonth(maxallowedtime.getMonth() - 6);

      // Zero the time component
      maxallowedtime.setHours(0, 0, 0, 0);

      // Get the time value in milliseconds and convert to seconds
      // console.log(currenttime);
      // console.log(maxallowedtime/1000|0);

      maxallowedenddate = maxallowedtime / 1000 | 0;
      /** LIMIT LIST DATA TO ROLLING 6 MONTHS ends **/

      var url = this.get("config.apiURL") + '/couriersPortal/consignments/searchCSV?page=' + this.get("page") + '&perPage=' + this.get("perPage") + "&token=" + this.get("session.data.authenticated.jwt"); //+ '&startDate=' + maxallowedenddate+ '&endDate=' + currenttime
      var queryParams = [];
      if (this.get("searchValue") !== "") {
        queryParams.pushObject("number=" + this.get("searchValue"));
      }
      if (this.get("searchState") !== "") {
        queryParams.pushObject("state=" + this.get("searchState"));
      }
      if (this.get("searchStartDate")) {
        queryParams.pushObject("startTime=" + (0, _moment['default'])(this.get("searchStartDate")).format("X"));
      } else {
        queryParams.pushObject("startTime=" + maxallowedenddate);
      }
      if (this.get("searchEndDate")) {
        queryParams.pushObject("endTime=" + (0, _moment['default'])(this.get("searchEndDate")).format("X"));
      } else {
        queryParams.pushObject("endTime=" + currenttime);
      }
      if (this.get("filterState")) {
        queryParams.pushObject('currentStates=' + this.get("filterState").map(function (x) {
          return x.id;
        }));
      }
      if (queryParams.length > 0) {
        url += '&' + queryParams.join('&');
      }
      return url;
    }).property('searchValue', 'searchState', 'searchStartDate', 'searchEndDate', 'page', 'perPage', 'config.apiURL'),

    perPageObserver: (function () {
      this.send("getPage", 1);
    }).observes('perPage'),

    actions: {
      search: function search() {
        this.send("getPage", 1);
      },

      getPage: function getPage(page) {

        /** LIMIT LIST DATA TO ROLLING 6 MONTHS **/
        var maxallowedenddate = 0;

        var currenttime = Math.floor(Date.now() / 1000);

        // Get a date object for the current time
        var maxallowedtime = new Date();

        // Set it to one month ago
        maxallowedtime.setMonth(maxallowedtime.getMonth() - 6);

        // Zero the time component
        maxallowedtime.setHours(0, 0, 0, 0);

        // Get the time value in milliseconds and convert to seconds
        // console.log(currenttime);
        // console.log(maxallowedtime/1000|0);

        maxallowedenddate = maxallowedtime / 1000 | 0;
        /** LIMIT LIST DATA TO ROLLING 6 MONTHS ends **/

        var self = this;

        // console.log("--here--");
        // console.log("--searchStartDate--"+this.get("searchStartDate"));
        // console.log("--searchEndDate--"+this.get("searchEndDate"));

        var url = 'couriersPortal/consignments/search?page=' + page + '&perPage=' + this.get("perPage"); // + '&startDate=' + maxallowedenddate+ '&endDate=' + currenttime;

        var queryParams = [];
        if (this.get("searchValue") !== "") {
          queryParams.pushObject("number=" + this.get("searchValue"));
        }
        if (this.get("searchState") !== "") {
          queryParams.pushObject("state=" + this.get("searchState"));
        }
        if (this.get("searchStartDate")) {
          queryParams.pushObject("startDate=" + (0, _moment['default'])(this.get("searchStartDate")).format("X"));
        } else {
          queryParams.pushObject("startDate=" + maxallowedenddate);
        }
        if (this.get("searchEndDate")) {
          queryParams.pushObject("endDate=" + (0, _moment['default'])(this.get("searchEndDate")).format("X"));
        } else {
          queryParams.pushObject("endDate=" + currenttime);
        }
        if (this.get("filterState")) {
          queryParams.pushObject('currentStates=' + this.get("filterState").map(function (x) {
            return x.id;
          }));
        }
        if (queryParams.length > 0) {
          url += '&' + queryParams.join('&');
        }

        this.get('applicationController').send('showLoading');
        this.get('ajax').request(url).then(function (result) {
          self.set('page', result.currentPage);
          self.set('totalPages', result.totalPages);
          var seenConsignments = new Map();
          result.consignments.forEach(function (con) {

            // con.articleLength = con.articles.length;
            con.articleLength = con.articles[0].adminMarkOffs.length;
            //  console.log("Test From Controller",con.articles[0].adminMarkOffs.length)
            if (seenConsignments.has(con.consignmentNumber)) {
              var e = seenConsignments.get(con.consignmentNumber);
              if (con.pickupReceipt) {
                e.pickupReceipts.pushObject(con.pickupReceipt);
              }
              seenConsignments.set(con.consignmentNumber, e);
            } else {
              con.pickupReceipts = [];
              if (con.pickupReceipt) {
                con.pickupReceipts.pushObject(con.pickupReceipt);
              }
              delete con.pickupReceipt;
              seenConsignments.set(con.consignmentNumber, con);
            }
          });
          self.set('consignments', [].concat(_toConsumableArray(seenConsignments)).map(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2);

            var k = _ref2[0];
            var v = _ref2[1];
            return v;
          }));
          self.get('applicationController').send('hideLoading');
        });
      }
    }
  });
});