define('couriers-portal/pods/unlinked/view/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service('notify'),
    barcode: null,
    consignmentNumber: null,
    consignmentError: false,
    consignment_number: "",
    consignment_id: null,
    tags: [],
    actions: {
      addTag: function addTag(tag) {
        this.get('tags').pushObject(tag);
      },
      removeTagAtIndex: function removeTagAtIndex(index) {
        this.get('tags').removeAt(index);
      },
      submitdetails: function submitdetails() {
        var _this = this;

        var self = this;
        var barcodesArr = self.get('tags');
        barcodesArr.push(this.get('barcode.webhookArticles.itemBarcode'));

        //his.consignment_id = this.barcode;
        //debugger

        this.consignment_id = this.get('barcode.consignmentId');
        if (this.consignment_id) {
          this.consignmentNumber = this.get('barcode.consignment_number');
        } else {
          this.consignmentNumber = this.get('consignment_number');
        }
        if (this.consignmentNumber) {
          self.set("consignmentError", false);
        } else {
          self.set("consignmentError", true);
          return false;
        }
        //let courieef = this.get('barcode.webhookArticles.courier');
        //let courieefssss =  this.get('barcode.webhookArticles.id');
        //debugger
        this.get('ajax').post('couriersPortal/manualCheckin', {
          data: JSON.stringify({
            courier_type: this.get('barcode.webhookArticles.courier'),
            webhook_articles_id: this.get('barcode.webhookArticles.id'),
            receiver_email: this.get("unlinked_Consignments.receiver_email"),
            receiver_phone: this.get("unlinked_Consignments.receiver_phone"),
            receiver_name: this.get("unlinked_Consignments.receiver_name"),
            consignment_number: this.consignmentNumber,
            consignment_id: this.get("barcode.consignmentId"),
            barcodes: barcodesArr
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function () {
          _this.set("consignment_number", "");
          _this.set('tags', []);

          _this.set("unlinked_Consignments.receiver_email", "");
          _this.set("unlinked_Consignments.receiver_phone", "");
          _this.set("unlinked_Consignments.receiver_name", "");
          _this.get('notify').success('Successfully Checked out.');
          _this.transitionToRoute('unlinked.index');
        }, function (errorResponse) {
          for (var i = 0; i < barcodesArr.length; i++) {
            if (barcodesArr[i] === _this.get('barcode.webhookArticles.itemBarcode')) {
              barcodesArr.splice(i, 1);
            }
          }
          if (errorResponse && errorResponse.errors && errorResponse.errors[0].detail !== "") {
            _this.get('notify').error(errorResponse.errors[0].detail);
          } else if (errorResponse && errorResponse.errors && errorResponse.errors[0].title !== "") {
            _this.get('notify').error(errorResponse.errors[0].title);
          } else if (errorResponse && errorResponse.errors && errorResponse.errors[0].detail !== "") {
            _this.get('notify').error(errorResponse.errors[0].detail);
          } else {
            _this.get('notify').error('Error updating details.');
          }
        });
      }
    }
  });
});