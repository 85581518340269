define('couriers-portal/pods/unlinked/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model(params) {
      return this.get('ajax').request('couriersPortal/unlinkConsignment?page=1&perpage=' + params.perPage);
    },

    setupController: function setupController(controller, model) {
      controller.set('page', model.Page);
      controller.set('totalPages', model.TotalPages);
      var seenConsignments = model.results;
      controller.set('consignments', seenConsignments);
    }
  });
});