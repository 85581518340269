define('couriers-portal/pods/locations/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		ajax: _ember['default'].inject.service(),

		/*model(params) {
  //return this.get('ajax').request('couriersPortal/stores/search?page=' + params.page + '&perPage=' + params.perPage);
  },
  
  setupController(controller, model) {
  controller.set('page', model.currentPage);
  controller.set('totalPages', model.totalPages);
  controller.set('stores', model.stores);
  } */
		setupController: function setupController(controller, model) {
			controller.set("isLoading", false);
			controller.set("consignmentNotFoundBool", false);
			controller.set("stores", '');
			if (controller.get("q")) {
				controller.send("search");
			}
		}
	});
});