define('couriers-portal/helpers/signature-url', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    config: _ember['default'].inject.service('config'),

    compute: function compute(params) {
      var id = params[0];
      var jwt = params[1];

      return this.get("config.apiURL") + "/couriersPortal/consignments/signature/" + id + "?token=" + jwt;
    }
  });
});