define('couriers-portal/router', ['exports', 'ember', 'couriers-portal/config/environment'], function (exports, _ember, _couriersPortalConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _couriersPortalConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login');

    this.route('pin');
    this.route('consignments');
    this.route('dashboard');
    this.route('reports');
    this.route('id-check');
    this.route('locations', function () {
      this.route('index', { path: "" });
      this.route('view', { path: ":id" });
    });
    this.route('unlinked', function () {
      this.route('index', { path: "" });
      this.route('view', { path: ":id" });
    });
  });

  exports['default'] = Router;
});