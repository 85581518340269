define('couriers-portal/pods/authenticated/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    appController: _ember['default'].inject.controller('application'),
    loading: _ember['default'].computed.alias('appController.loading'),

    actions: {

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});