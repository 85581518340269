define('couriers-portal/pods/unlinked/view/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'couriers-portal/shapes/unlinkedconsignments'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _couriersPortalShapesUnlinkedconsignments) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.set('tags', []);
      controller.set('unlinked_Consignments', null);
      controller.set('unlinked_Consignments', _couriersPortalShapesUnlinkedconsignments['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.get('ajax').request('couriersPortal/getWebhookArticles?page=1&perPage=20&id=' + model.id).then(function (response) {
        controller.set("barcode", response.results);
      }, console.log);
    }
  });
});