define('couriers-portal/pods/dashboard/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		ajax: _ember['default'].inject.service(),

		beforeModel: function beforeModel() {
			this.transitionTo('pin');
		}

	});
});
/*setupController(controller) {
	this.get('ajax').request("couriersPortal/stats?state=").then((result) => {
	  controller.set('dashboard.all', result);
	});
}*/