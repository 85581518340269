define('couriers-portal/pods/consignments/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),

    model: function model(params) {

      /** LIMIT LIST DATA TO ROLLING 6 MONTHS **/
      var maxallowedenddate = 0;

      var currenttime = Math.floor(Date.now() / 1000);

      // Get a date object for the current time
      var maxallowedtime = new Date();

      // Set it to one month ago
      maxallowedtime.setMonth(maxallowedtime.getMonth() - 6);

      // Zero the time component
      maxallowedtime.setHours(0, 0, 0, 0);

      // Get the time value in milliseconds and convert to seconds
      maxallowedenddate = maxallowedtime / 1000 | 0;
      /** LIMIT LIST DATA TO ROLLING 6 MONTHS ends **/
      return this.get('ajax').request('couriersPortal/consignments/search?page=' + params.page + '&perPage=' + params.perPage + '&startDate=' + maxallowedenddate + '&endDate=' + currenttime);
    },

    setupController: function setupController(controller, model) {
      controller.set('endDateSelection', new Date());

      var currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 6);
      controller.set('startDateSelection', currentDate);

      controller.set('page', model.currentPage);
      controller.set('totalPages', model.totalPages);
      var seenConsignments = new Map();
      model.consignments.forEach(function (con) {
        con.articleLength = con.articles[0].adminMarkOffs.length;
        if (seenConsignments.has(con.consignmentNumber)) {
          var e = seenConsignments.get(con.consignmentNumber);
          if (con.pickupReceipt) {
            e.pickupReceipts.pushObject(con.pickupReceipt);
          }
          seenConsignments.set(con.consignmentNumber, e);
        } else {
          con.pickupReceipts = [];
          if (con.pickupReceipt) {
            con.pickupReceipts.pushObject(con.pickupReceipt);
          }
          delete con.pickupReceipt;
          seenConsignments.set(con.consignmentNumber, con);
        }
      });
      controller.set('consignments', [].concat(_toConsumableArray(seenConsignments)).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var k = _ref2[0];
        var v = _ref2[1];
        return v;
      }));
    }
  });
});