define('couriers-portal/pods/locations/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),
    applicationController: _ember['default'].inject.controller('application'),
    config: _ember['default'].inject.service('config'),

    queryParams: ["page", "perPage", "searchValue"],
    page: 1,
    perPage: 5,
    totalPages: null,

    searchValue: "",
    searchState: "",
    stateValues: ["NSW", "QLD", "TAS", "VIC", "NT", "WA", "SA", "ACT"],
    perPageValues: [5, 10, 25, 50, 100],
    consignmentNotFoundBool: false,

    csvURL: (function () {
      var url = this.get("config.apiURL") + '/couriersPortal/stores/searchCSV?token=' + this.get("session.data.authenticated.jwt");
      var queryParams = [];
      if (this.get("searchValue") !== "") {
        queryParams.pushObject("q=" + this.get("searchValue"));
      }
      if (this.get("searchState") !== "") {
        queryParams.pushObject("state=" + this.get("searchState"));
      }
      if (queryParams.length > 0) {
        url += '&' + queryParams.join('&');
      }
      return url;
    }).property('searchValue', 'searchState', 'config.apiURL'),

    perPageObserver: (function () {
      this.send("getPage", 1);
    }).observes('perPage'),

    actions: {
      search: function search() {
        this.send("getPage", 1);
      },
      valiadte_search: function valiadte_search() {
        if (this.get("searchValue") !== "") {
          var tempVal = this.get("searchValue").trim();
          this.set("searchValue", tempVal);
        }
      },
      getPage: function getPage(page) {
        var self = this;
        if (this.get("searchValue") !== "" && this.get("searchValue") !== undefined || this.get("searchState") !== "" && this.get("searchState") !== undefined && this.get("searchState") !== null) {
          self.set("isLoading", true);
          var url = 'couriersPortal/stores/search?page=' + page + '&perPage=' + this.get("perPage");
          if (this.get("searchValue") !== "") {
            url += "&q=" + this.get("searchValue");
          }
          if (this.get("searchState") !== "" && this.get("searchState") !== null) {
            url += "&state=" + this.get("searchState");
          }
          this.get('applicationController').send('showLoading');
          this.get('ajax').request(url).then(function (result) {
            self.set('page', result.currentPage);
            self.set('totalPages', result.totalPages);
            self.set('stores', result.stores);
            self.get('applicationController').send('hideLoading');
          });
          self.set("isLoading", false);
        } else {
          self.set("isLoading", false);
          self.set("searchValue", "");
          self.set("searchState", "");
          self.set('stores', null);
        }
      }
    }
  });
});