define('couriers-portal/shapes/unlinkedconsignments', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    receiver_name: {
      description: "Receiver Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    receiver_phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    }
  });

  // receiver_email: [
  //   validator('presence', true),
  //   validator('format', {
  //     regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  //     message: 'Invalid email'
  //   }),
  // ],
  exports['default'] = _ember['default'].Object.extend(Validations, {
    receiver_name: null,
    // receiver_email: null,
    receiver_phone: null
    //consignment_number: null
  });
});