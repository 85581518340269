define("couriers-portal/pods/dashboard/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    ajax: _ember["default"].inject.service(),

    dashboard: {
      all: {
        today: {
          "in": "Loading..."
        }
      },
      nsw: {
        today: {
          "in": "Pending load"
        }
      },
      act: {
        today: {
          "in": "Pending load"
        }
      },
      tas: {
        today: {
          "in": "Pending load"
        }
      },
      vic: {
        today: {
          "in": "Pending load"
        }
      },
      nt: {
        today: {
          "in": "Pending load"
        }
      },
      qld: {
        today: {
          "in": "Pending load"
        }
      },
      wa: {
        today: {
          "in": "Pending load"
        }
      },
      sa: {
        today: {
          "in": "Pending load"
        }
      }
    },

    stateSelected: "Global",

    actions: {
      selectState: function selectState(state) {
        var _this = this;

        if (state !== "Global" && this.get("dashboard." + state.toLowerCase() + ".today.in") === "Pending load") {
          this.set("dashboard." + state.toLowerCase() + ".today.in", "Loading...");
          this.get('ajax').request("couriersPortal/stats?state=" + state).then(function (data) {
            _this.set("dashboard." + state.toLowerCase(), data);
          });
        }
        this.set("stateSelected", state);
      }
    }
  });
});