define("couriers-portal/helpers/person-helper", ["exports", "ember"], function (exports, _ember) {
  exports.personHelper = personHelper;

  function personHelper(params) {
    var person = params[0];
    var personMissing = params[1];
    var personFull = person.name;
    if (personFull === "") {
      personFull = personMissing;
    }
    return personFull;
  }

  exports["default"] = _ember["default"].Helper.helper(personHelper);
});