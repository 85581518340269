define('couriers-portal/helpers/received-on', ['exports', 'ember'], function (exports, _ember) {
  exports.receivedOn = receivedOn;

  function receivedOn(params) {
    var consignment = params[0];
    var firstDate = null;
    consignment.articles.forEach(function (a) {
      var statesArr = a.states;
      var copyArray = Object.assign([], statesArr).sort(function (a, b) {
        return a.time - b.time;
      });
      copyArray.forEach(function (s) {
        if (firstDate) {
          if (firstDate > s.time) {
            firstDate = s.time;
          }
          if (s.state === 'notYetCheckedIn' || s.state === 'awaitingCustomerPickup') {
            firstDate = s.time;
          }
        } else {
          firstDate = s.time;
        }
      });
    });
    return firstDate;
  }

  exports['default'] = _ember['default'].Helper.helper(receivedOn);
});