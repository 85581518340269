define('couriers-portal/initializers/material-lite-extensions', ['exports', 'ember-material-lite/initializers/material-lite-extensions'], function (exports, _emberMaterialLiteInitializersMaterialLiteExtensions) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMaterialLiteInitializersMaterialLiteExtensions['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberMaterialLiteInitializersMaterialLiteExtensions.initialize;
    }
  });
});