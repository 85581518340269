define("couriers-portal/helpers/article-state-date", ["exports", "ember"], function (exports, _ember) {
  exports.articleStateDate = articleStateDate;

  function articleStateDate(params) {
    var article = params[0];
    var states = params[1].split(",");
    var search = article.states.filter(function (obj) {
      return states.indexOf(obj.state) > -1;
    });
    if (search.length > 0) {
      return search[search.length - 1].time;
    } else {
      return 0;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(articleStateDate);
});