define('couriers-portal/pods/pin/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        setupController: function setupController(controller) {
            controller.set("isLoading", false);
            controller.set("isShowingModal", false);
            controller.set("consignmentNotFoundBool", false);
            if (controller.get("q")) {
                controller.send("search");
            }
        }
    });
});