define("couriers-portal/helpers/state-helper", ["exports", "ember"], function (exports, _ember) {
  exports.stateHelper = stateHelper;

  function stateHelper(params) {
    var state = params[0];
    switch (state) {
      case "notYetCheckedIn":
        return "Courier Checkin";
      case "awaitingCustomerPickup":
        return "Newsagency Checkin";
      case "awaitingCourierPickup":
        return "Return Requested";
      case "awaitingCourierPickupOutbound":
        return "Outbound Parcel";
      case "customerHasPickedUp":
        return "Delivered";
      case "courierHasPickedUp":
        return "Returned To Courier";
      case "processedExternally":
        return "Processed Externally";
      case "pre-advice":
        return "Pre Advice";
      default:
        return "Unknown State";
    }
  }

  exports["default"] = _ember["default"].Helper.helper(stateHelper);
});