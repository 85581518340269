define("couriers-portal/helpers/dlb-format", ["exports", "ember"], function (exports, _ember) {
  exports.dlbFormat = dlbFormat;

  function dlbFormat(params) {
    var dlb = params[0];
    var pad = "0000000";
    var paddedDLB = (pad + dlb).slice(-pad.length);
    return "LOC" + paddedDLB;
  }

  exports["default"] = _ember["default"].Helper.helper(dlbFormat);
});