define('couriers-portal/pods/reports/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    startDate: new Date(),
    endDate: new Date(),
    sevenLockerActivityReportURL: _ember['default'].computed('startDate', 'endDate', 'session.data.authenticated', 'config.apiURL', function () {
      var jwt = this.get('session.data.authenticated.jwt');
      var startDate = moment(this.get("startDate")).format("YYYY-MM-DD");
      var endDate = moment(this.get("endDate")).format("YYYY-MM-DD");
      return this.get("config.apiURL") + '/couriersPortal/exports/sevenEleven/lockers/overall?start=' + startDate + '&end=' + endDate + '&token=' + jwt;
    }),
    actions: {}
  });
});