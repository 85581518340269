define('couriers-portal/pods/unlinked/index/controller', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),
    applicationController: _ember['default'].inject.controller('application'),
    config: _ember['default'].inject.service('config'),

    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 10,
    totalPages: null,

    searchValue: "",
    searchStartDate: "",
    searchEndDate: "",
    searchBool: false,
    filterState: [],

    //courierValues: [{value: "All", id: "all"}, {value: "Couriers Please", id: "cpl"}, {value: "Toll", id: "toll"},{value: "DHL", id: "dhl"}],

    perPageValues: [10, 25, 50, 100],

    perPageObserver: (function () {
      this.send("getPage", 1);
    }).observes('perPage'),

    actions: {
      search: function search() {
        if (this.get("searchValue") === "") {
          this.set("searchBool", false);
        } else {
          this.set("searchBool", true);
        }
        this.send("getPage", 1);
      },
      getPage: function getPage(page) {
        var self = this;
        var url = 'couriersPortal/unlinkConsignment?page=' + page + '&perpage=' + this.get("perPage");
        var queryParams = [];
        if (this.get("searchBool") && this.get("searchValue") !== "") {
          queryParams.pushObject("item_barcode=" + this.get("searchValue"));
        }
        if (queryParams.length > 0) {
          url += '&' + queryParams.join('&');
        }

        this.get('applicationController').send('showLoading');
        this.get('ajax').request(url).then(function (result) {
          self.set('page', result.Page);
          self.set('totalPages', result.TotalPages);
          var seenConsignments = result.results;
          self.set('consignments', seenConsignments);
          self.get('applicationController').send('hideLoading');
        });
      }
    }
  });
});