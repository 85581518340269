define('couriers-portal/pods/login/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    email: "",
    password: "",

    formValid: (function () {
      //Current HTML5 Standard of Email Regex
      //http://www.w3.org/TR/html5/forms.html#valid-e-mail-address
      var emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      return this.get('email').length > 0 && this.get('password').length > 0 && emailRegex.exec(this.get('email')) !== null;
    }).property('email', 'password'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('email', 'password');

        var email = _getProperties.email;
        var password = _getProperties.password;

        this.get('session').authenticate('authenticator:jwt', email, password)['catch'](function (reason) {
          if (reason === "Ajax authorization failed") {
            _this.set('errorMessage', "Invalid Credentials");
          } else if (reason === "Ajax operation failed") {
            _this.set('errorMessage', "Connection Failed");
          } else {
            _this.set('errorMessage', reason);
          }
        });
      }
    }
  });
});