define('couriers-portal/pods/id-check/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),
    applicationController: _ember['default'].inject.controller('application'),

    idSearch: "",
    result: null,
    searched: false,

    actions: {
      search: function search() {
        var self = this;
        this.set("searched", true);
        var url = 'v2/international/id-check/' + this.get("idSearch");
        this.get('applicationController').send('showLoading');
        this.get('ajax').request(url).then(function (result) {
          self.set("result", result);
          self.get('applicationController').send('hideLoading', 300);
        })['catch'](function () {
          self.set("result", null);
          self.get('applicationController').send('hideLoading', 300);
        });
      }
    }
  });
});