define('couriers-portal/services/config', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    __config__: _ember['default'].computed(function () {
      var originalConfig = _ember['default'].getOwner(this).resolveRegistration('config:environment');
      var configOverrides = window.config;
      var finalConfig = _ember['default'].$.extend({}, originalConfig, configOverrides);
      return finalConfig;
    }),

    unknownProperty: function unknownProperty(path) {
      return this.get('__config__.' + path);
    }
  });
});