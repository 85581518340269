define("couriers-portal/helpers/consignment-type", ["exports", "ember"], function (exports, _ember) {
  exports.consignmentType = consignmentType;

  function consignmentType(params) {
    var consignment = params[0];
    var consignmentType = "Inbound";
    consignment.articles.forEach(function (a) {
      a.states.forEach(function (s) {
        if (s.state === "awaitingCourierPickupOutbound") {
          consignmentType = "Outbound";
        }
      });
    });
    return consignmentType;
  }

  exports["default"] = _ember["default"].Helper.helper(consignmentType);
});