define('couriers-portal/pods/components/consignment-details/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),

    tagName: 'tr',

    jwt: _ember['default'].computed("session.data.authenticated.jwt", function () {
      return this.get("session.data.authenticated.jwt");
    }),
    actions: {
      addName: function addName() {
        var self = this;
        console.log(this.get("name"));
        this.get('ajax').post("/couriersPortal/consignments/" + this.get("consignment.id") + "/alternatePickupName", {
          data: JSON.stringify({ name: this.get("name") }),
          dataType: "text"
        }).then(function () {
          self.get("onComplete")();
        });
      }
    }
  });
});