define('couriers-portal/helpers/inline-svg', ['exports', 'ember', 'couriers-portal/svgs', 'ember-inline-svg/utils/general'], function (exports, _ember, _couriersPortalSvgs, _emberInlineSvgUtilsGeneral) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.inlineSvg = inlineSvg;

  function inlineSvg(path, options) {
    var jsonPath = (0, _emberInlineSvgUtilsGeneral.dottify)(path);
    var svg = _ember['default'].get(_couriersPortalSvgs['default'], jsonPath);

    // TODO: Ember.get should return `null`, not `undefined`.
    // if (svg === null && /\.svg$/.test(path))
    if (typeof svg === "undefined" && /\.svg$/.test(path)) {
      svg = _ember['default'].get(_couriersPortalSvgs['default'], jsonPath.slice(0, -4));
    }

    _ember['default'].assert("No SVG found for " + path, svg);

    svg = (0, _emberInlineSvgUtilsGeneral.applyClass)(svg, options['class']);

    return new _ember['default'].Handlebars.SafeString(svg);
  }

  var helper = undefined;
  if (_ember['default'].Helper && _ember['default'].Helper.helper) {
    helper = _ember['default'].Helper.helper(function (_ref, options) {
      var _ref2 = _slicedToArray(_ref, 1);

      var path = _ref2[0];

      return inlineSvg(path, options);
    });
  } else {
    helper = _ember['default'].Handlebars.makeBoundHelper(function (path, options) {
      return inlineSvg(path, options.hash || {});
    });
  }

  exports['default'] = helper;
});