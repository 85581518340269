define("couriers-portal/helpers/pretty-address", ["exports", "ember"], function (exports, _ember) {
  exports.prettyAddress = prettyAddress;

  function prettyAddress(params) {
    var value = params[0];
    if (value.street2) {
      return value.street1 + " " + value.street2 + ", " + value.city + ", " + value.state + " " + value.postcode;
    } else {
      return value.street1 + ", " + value.city + ", " + value.state + " " + value.postcode;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(prettyAddress);
});