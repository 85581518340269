define('couriers-portal/pods/pin/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    consignments: null,
    q: "",
    queryParams: ['q'],
    isShowingModal: false,
    consignmentNotFoundBool: false,

    pin: null,
    voucher: null,

    actions: {
      valiadte_search: function valiadte_search() {
        if (this.get("searchValue") !== "") {
          var tempVal = this.get("searchValue").trim();
          this.set("searchValue", tempVal);
        }
      },
      search: function search() {
        var self = this;
        if (this.get("searchValue") !== "" && this.get("searchValue") !== undefined) {
          self.set("isLoading", true);
          this.get('ajax').request('couriersPortal/consignments/search?page=1&perPage=10&number=' + this.get("searchValue")).then(function (results) {
            if (results && results.consignments && results.consignments.length > 0) {
              self.set('consignments', results.consignments);
              self.set('consignmentNotFoundBool', false);
            } else {
              self.set('consignments', null);
              self.set('consignmentNotFoundBool', true);
            }
            self.set("isLoading", false);
          }, function (error) {
            self.set("isLoading", false);
            self.set('consignments', null);
            self.set('consignmentNotFoundBool', true);
            console.log(error);
          });
        } else {
          self.set("searchValue", " ");
          self.set('consignments', null);
        }
      },
      showPinForm: function showPinForm(consignment) {
        var _this = this;

        this.set("isShowingModal", true);
        this.set("current", consignment);

        this.get('ajax').post('couriersPortal/consignments/' + consignment.id + '/pin').then(function (result) {
          if (result.pin) {
            _this.set('pin', result.pin);
          }
        }, function (error) {
          console.log(error);
        });
      },
      closePinForm: function closePinForm() {
        this.set("isShowingModal", false);
        this.set("pin", null);
        this.set("current", null);
      }
    }
  });
});