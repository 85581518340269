define('couriers-portal/pods/locations/view/controller', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service("session"),
    applicationController: _ember['default'].inject.controller('application'),

    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 10,
    totalPages: null,
    store: null,
    consignments: [],

    filterState: [],
    stateValues: [{ id: 0, value: "Courier Checkin" }, { id: 1, value: "Newsagency Checkin" }, { id: 2, value: "Delivered" }, { id: 3, value: "Courier Return Requested" }, { id: 4, value: "Courier Return Picked Up" }, { id: 5, value: "Outbound Consignment Lodged" }, { id: 6, value: "Awaiting Courier Pickup Outbound" }, { id: 7, value: "Processed Externally" }],

    actions: {

      filter: function filter() {
        this.get('applicationController').send('showLoading');
        this.send('getPage', 1);
      },

      getPage: function getPage(page) {
        var self = this;
        var url = 'couriersPortal/stores/' + this.get("store.id") + '?page=' + page + '&perPage=' + this.get("perPage");
        var queryParams = [];
        if (this.get("filterState")) {
          queryParams.pushObject('currentStates=' + this.get("filterState").map(function (x) {
            return x.id;
          }));
        }
        if (queryParams.length > 0) {
          url += '&' + queryParams.join('&');
        }
        this.get('ajax').request(url).then(function (result) {
          self.set('page', result.currentPage);
          self.set('totalPages', result.totalPages);
          var seenConsignments = new Map();
          result.consignments.forEach(function (con) {
            if (seenConsignments.has(con.consignmentNumber)) {
              var e = seenConsignments.get(con.consignmentNumber);
              if (con.pickupReceipt) {
                e.pickupReceipts.pushObject(con.pickupReceipt);
              }
              seenConsignments.set(con.consignmentNumber, e);
            } else {
              con.pickupReceipts = [];
              if (con.pickupReceipt) {
                con.pickupReceipts.pushObject(con.pickupReceipt);
              }
              delete con.pickupReceipt;
              seenConsignments.set(con.consignmentNumber, con);
            }
          });
          self.set('consignments', [].concat(_toConsumableArray(seenConsignments)).map(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2);

            var k = _ref2[0];
            var v = _ref2[1];
            return v;
          }));
          self.set('store', result.store);
          self.get('applicationController').send('hideLoading');
        });
      }
    }
  });
});