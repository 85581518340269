define('couriers-portal/pods/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),

    renderTemplate: function renderTemplate() {
      if (this.get('session.isAuthenticated')) {
        this.render('authenticated');
      } else {
        this.render('unauthenticated');
      }
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this.render('authenticated');
      this._super.apply(this, arguments);
    },

    actions: {
      loading: function loading() {
        this.controllerFor('application').send('showLoading');
        this.router.on('didTransition', this, function () {
          this.controllerFor('application').send('hideLoading', 350);
        });
      }
    }
  });
});