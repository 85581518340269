define('couriers-portal/pods/application/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    loading: false,

    actions: {
      showLoading: function showLoading() {
        this.set('loading', true);
      },

      hideLoading: function hideLoading(delay) {
        var self = this;
        _ember['default'].run.later(function () {
          self.set('loading', false);
        }, delay);
      }
    }
  });
});