define('couriers-portal/authenticators/jwt', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase) {
  var RSVP = _ember['default'].RSVP;
  var run = _ember['default'].run;
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    restore: function restore(data) {
      return RSVP.resolve(data);
    },

    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var data = { email: identification, password: password };
        _this.get('ajax').post('couriersPortal/auth/login', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(data)
        }).then(function (response) {
          run(null, resolve, response);
        }, function (xhr) {
          console.log(xhr.errors);
          if (xhr.errors[0] && xhr.errors[0].status === "0") {
            _ember['default'].run(null, reject, "Connection Failed");
          } else if (xhr.errors[0] && xhr.errors[0].status === "401") {
            _ember['default'].run(null, reject, "Invalid Credentials");
          } else if (xhr.errors[0] && xhr.errors[0].status === "429") {
            _ember['default'].run(null, reject, "You have been locked out of the system after too many failed login attempts. This will reset in 30 minutes.");
          } else {
            _ember['default'].run(null, reject, "System Error");
          }
        })['catch'](function (reason) {
          run(null, reject, reason.message);
        });
      });
    },

    invalidate: function invalidate() {
      return RSVP.resolve();
    }
  });
});