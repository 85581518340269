define('couriers-portal/helpers/environment-flag', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    config: _ember['default'].inject.service('config'),

    compute: function compute() {
      switch (this.get("config.environmentFlag")) {
        case "development":
          return "[Dev]";
        case "staging":
          return "[Staging]";
        case "demo":
          return "[Demo]";
      }
      return "";
    }
  });
});