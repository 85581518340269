define('couriers-portal/helpers/days-between', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports.daysBetween = daysBetween;

  function daysBetween(params) {
    var a = (0, _moment['default'])(params[0], "x");
    var b = (0, _moment['default'])(params[1], "x");
    if (!params[1]) {
      b = (0, _moment['default'])();
    }
    return Math.ceil(b.diff(a, 'days', true));
  }

  exports['default'] = _ember['default'].Helper.helper(daysBetween);
});