define('couriers-portal/pods/components/consignment-row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: 'tr',

    classNameBindings: ['isOpen'],
    isOpen: false,

    click: function click() {
      this.toggleProperty("isOpen");
    }
  });
});